import React from "react";
import styles from "./VideoSection.module.scss";
import StartFreeButton from "../../cmps/StartFreeButton/StartFreeButton.cmp";
// import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface VideoSectionProps {
  onclickOpenModal?: (annualBilling?: boolean) => void;
  onClickMethod?: () => void;
  scrollToTop?: boolean;
  buttonTitle?: string;
}

const VideoSection = ({
  onclickOpenModal,
  scrollToTop,
  buttonTitle,
  onClickMethod,
}: VideoSectionProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.videoSectionMainContainer}>
      <div className={styles.contentContainer}>
        <video
          className={styles.videoPlayerSide}
          autoPlay
          //   controls
          playsInline
          muted
          loop
          //   src="https://unichatwebbapp.s3.amazonaws.com/video-ai.mp4"
        >
          <source
            src={
              i18n.language.includes("he")
                ? "https://unichatwebbapp.s3.amazonaws.com/video-ai.mp4"
                : "https://unichatwebbapp.s3.amazonaws.com/IMG_0034.MP4"
            }
            type="video/mp4"
          />
        </video>
      </div>
      <div className={styles.contentContainer}>
        <div
          className={styles.textContentAndButton}
          style={{ direction: i18n.language.includes("he") ? "rtl" : "ltr" }}
        >
          <p>
            {t("videoSection.firstSpan")}
            <br />
            {t("videoSection.secondSpan")}
          </p>
          <StartFreeButton
            title={
              buttonTitle ? buttonTitle : t("StartFreeButton.StartForFree")
            }
            onClickMethod={
              onclickOpenModal
                ? onclickOpenModal
                : onClickMethod
                ? onClickMethod
                : () => {}
            }
            scrollToTop={scrollToTop}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
