import React, { useState } from "react";
import styles from "./WhatsappRegistration.module.scss";
import InsertWhatsAppNumber from "../InsertWhatsAppNumber/InsertWhatsAppNumber.cmp";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import { Button, CircularProgress } from "@mui/material";
import { getAllUTMData, storage } from "../../utils/utils";
import { userVerification } from "../../utils/service";
import OTPModal from "../OTPModal/OTPModal.cmp";
import whatsAppLogo from "../../assets/whiteWhatsApp.png";

interface WhatsappRegistrationProps {
  setStep: (number: number) => void;
  setRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}

type languageCode = "IL" | "US" | "GB" | "TR";

const WhatsappRegistration = ({
  setStep,
  setRegistered,
}: WhatsappRegistrationProps) => {
  const recoilLangModel = useRecoilValue(languageModel);
  const [t, i18n] = useTranslation();
  const [phoneNumber, setPhoneNumber] = useRecoilState<any>(clientPhoneNumber);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userToken, setUserToken] = useState<string>("");
  const [openOTPModal, setOpenOTPModal] = useState<boolean>(false);

  const sendPhoneNumberValidate = async () => {
    setIsLoading(true);
    if (!sendDataValidate()) {
      setIsLoading(false);
      return;
    }

    try {
      const utmData = getAllUTMData(phoneNumber, "whatsApp");
      const userPhone = phoneNumber.slice(1);
      const response = await userVerification.phoneNumberSignIn(
        userPhone,
        utmData
      );

      storage.saveToStorage("userId", response.userId);
      handleReponseAndOpenOTP(response);
      storage.saveToStorage("userPhone", userPhone);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage("Error login");
    }
  };

  const sendDataValidate = (): boolean => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      if (errorMessage !== "") {
        setErrorMessage("");
      }
      return true;
    }
    setErrorMessage(t("LoginPage.HelperErrorMessage.WhatsApp"));
    return false;
  };

  const handleReponseAndOpenOTP = (response: any) => {
    setUserToken(response.token);
    storage.saveToStorage("userId", response.userId);
    if (response.newUser) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "start_sign_up",
        user_id: response.userId,
        user_email: null,
        user_phone: phoneNumber,
      });
    }
    setOpenOTPModal(true);
    setIsLoading(false);
    setErrorMessage("");
    // response.newUser && setIsNewUser(true)
  };

  return (
    <div className={styles.whatsAppRegistrationMainContainer}>
      <section className={styles.whatsAppRegistrationHeader}>
        <span>7</span>
        <h1>{t("whatsappRegistration.title")}</h1>
        {/* <p>הכרטיס לא יחויב לפני תום תקופת הניסיון</p> */}
      </section>
      <hr />
      <section className={styles.whatsAppRegistrationSecondaryContainer}>
        <h3>{t("whatsappRegistration.secondTitle")}</h3>
        <form
        // onSubmit={(e) => {
        //   console.log("Form submitted");
        //   // e.preventDefault();
        //   sendPhoneNumberValidate();
        // }}
        >
          <PhoneInput
            defaultCountry={
              // recoilLangModel.toLocaleLowerCase().includes("he") ? "IL" : "US"
              i18n.language.split("-")[1]
                ? (i18n.language.split("-")[1] as languageCode)
                : "US"
            }
            placeholder={t("whatsappRegistration.placeholder")}
            value={phoneNumber}
            withCountryCallingCode
            international
            onChange={setPhoneNumber}
            rules={{ required: true }}
            className={styles.PhoneInput}
          />
          {errorMessage && (
            <span
              className={`${styles.helperText} ${styles.errorMessage}`}
              style={{
                visibility: errorMessage === " " ? "hidden" : "visible",
                fontSize: "0.75rem",
                alignSelf: "flex-end",
                margin: "3px 0 0 14px",
              }}
            >
              {errorMessage === " " ? "error" : errorMessage}
            </span>
          )}
          <Button
            className={styles.continueButton}
            type="submit"
            onClick={sendPhoneNumberValidate}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <div>
                <span>{t("whatsappRegistration.registrationButton")}</span>
                <img
                  src={whatsAppLogo}
                  alt="whatsapp logo"
                  height={30}
                  width={30}
                />
              </div>
            )}
          </Button>
        </form>
      </section>
      {openOTPModal && (
        <OTPModal
          closeModal={setOpenOTPModal}
          setStep={setStep}
          setRegistered={setRegistered}
          userToken={userToken}
          isWhatsapp
        />
      )}
    </div>
  );
};

export default WhatsappRegistration;
