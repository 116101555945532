import { UniAccordion } from "../cmps/AboutAccordion/AboutAccordion.cmp";
import { AboutInfoCardInterface } from "../cmps/AboutInfoCard/AboutInfoCard.cmp";
import { ExampleImgCardType } from "../cmps/ExampleImgCard/ExampleImgCard.cmp";
import { FigureCard } from "../cmps/FiguresCard/FiguresCard.cmp";
import { PricingPlanCard } from "../cmps/PricingPlanCard/PricingPlanCards.cmp";
import { ServiceDataCardsInterface } from "../cmps/ServiceDataCard/ServiceDataCard.cmp";
import {
  customersIMG,
  designIMG,
  trophyIMG,
  savingMoney,
  hrConsulting,
  businessInvestment,
  businessAudit,
  langIcon,
  bookCover,
  webSiteMockup,
  tShirtSimulation,
  productSimulation,
  printSiumlation,
  logoDesign,
  interiorDesignSimulation,
  dishSimulation,
  appDesign,
  singlePayedAccount,
  availableAnyWhere,
  oneForAll,
  gpt3,
  gpt4,
  claude2,
  stableDiffusion,
  dalle3,
  hermes,
  faceSwap,
  midjourney,
  phind,
} from "./assetsImports";

import i18n from "./i18n";
// console.log(i18n.t('figureCardsData.FigureCardData1'))

const getServiceDataCardsData = (): ServiceDataCardsInterface[] => [
  {
    imgSource: availableAnyWhere,
    headerTitle: i18n.t("ServiceDataCards.DataCard1.headerTitle"),
    paragraphText: i18n.t("ServiceDataCards.DataCard1.paragraphText"),
  },
  {
    imgSource: oneForAll,
    headerTitle: i18n.t("ServiceDataCards.DataCard2.headerTitle"),
    paragraphText: i18n.t("ServiceDataCards.DataCard2.paragraphText"),
  },
  {
    imgSource: singlePayedAccount,
    headerTitle: i18n.t("ServiceDataCards.DataCard3.headerTitle"),
    paragraphText: i18n.t("ServiceDataCards.DataCard3.paragraphText"),
  },
];

export type PricingCardsOptions = {
  free: any[];
  basic: any[];
  premium: any[];
  pro: any[];
};
const getAccordionsData = (): UniAccordion[] => [
  {
    accordionHeader: i18n.t("FaqSection.accordions.first.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.first.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.second.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.second.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.third.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.third.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.forth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.forth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.fifth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.fifth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.sixth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.sixth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.seventh.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.seventh.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.eighth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.eighth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.ninth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.ninth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.tenth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.tenth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordions.eleventh.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordions.eleventh.accordionData"),
  },
];
const getFreeAccordionsData = (): UniAccordion[] => [
  {
    accordionHeader: i18n.t("FaqSection.accordionsFree.first.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordionsFree.first.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordionsFree.second.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordionsFree.second.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordionsFree.third.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordionsFree.third.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordionsFree.forth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordionsFree.forth.accordionData"),
  },
  {
    accordionHeader: i18n.t("FaqSection.accordionsFree.fifth.accordionHeader"),
    accordionData: i18n.t("FaqSection.accordionsFree.fifth.accordionData"),
  },
];

const infoCardsData: AboutInfoCardInterface[] = [
  {
    icon: businessInvestment,
    header: "Business Investment",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.",
    details: "More Details",
  },
  {
    icon: businessAudit,
    header: "Business Audit",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.",
    details: "More Details",
  },
  {
    icon: hrConsulting,
    header: "HR Consulting",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.",
    details: "More Details",
  },
  {
    icon: savingMoney,
    header: "Saving Money",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.",
    details: "More Details",
  },
];

const getPricingCardsData = (): PricingPlanCard[] => [
  // { planType: "free", pricing: 0 },
  { planType: "basic", pricing: 7.99 },
  { planType: "premium", pricing: 12.99 },
  { planType: "pro", pricing: 19.99 },
];

const getFigureCardsData = (): FigureCard[] => [
  {
    asset: customersIMG,
    number: 150,
    symbol: "K",
    data: i18n.t("figureCardsData.FigureCardData1"),
  },
  {
    asset: designIMG,
    number: 30,
    symbol: "K",
    data: i18n.t("figureCardsData.FigureCardData2"),
  },
  {
    asset: trophyIMG,
    number: 5,
    symbol: "M",
    data: i18n.t("figureCardsData.FigureCardData3"),
  },
  {
    asset: langIcon,
    number: 100,
    symbol: "",
    data: i18n.t("figureCardsData.FigureCardData4"),
  },
];

export const pricingCardsOptions: PricingCardsOptions = {
  free: [
    {
      model: {
        value: i18n.t("pricingCardsData.free.options.third"),
        credits: i18n.t(
          "pricingCardsData.free.pricingCardsRequestsCount.GPT3.5"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.free.options.fourth"),
        credits: i18n.t(
          "pricingCardsData.free.pricingCardsRequestsCount.StableDiffusion"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.free.options.fifth"),
        credits: i18n.t(
          "pricingCardsData.free.pricingCardsRequestsCount.ClaudeInstant"
        ),
      },
    },
    // { model: { value: i18n.t('pricingCardsData.models.18'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.UnlimitedImages') } },
    // { model: { value: i18n.t('pricingCardsData.models.3'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.NousHermess13B') } },
    // { model: { value: i18n.t('pricingCardsData.models.4'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.Mistral7BInstruct') } },
    // { model: { value: i18n.t('pricingCardsData.models.5'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.MetaLlamav213B') } },
    // { model: { value: i18n.t('pricingCardsData.models.7'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.GooglePaLM2Bison') } },
    // { model: { value: i18n.t('pricingCardsData.models.8'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.GooglePaLM2BisonCode') } },
  ],
  basic: [
    {
      model: {
        value: i18n.t("pricingCardsData.models.25"),
        credits: i18n.t(
          "pricingCardsData.basic.pricingCardsRequestsCount.GPT4o"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.12"),
        credits: i18n.t(
          "pricingCardsData.basic.pricingCardsRequestsCount.DallE3"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.26"),
        credits: i18n.t(
          "pricingCardsData.basic.pricingCardsRequestsCount.Claude3.5"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.9"),
        credits: i18n.t(
          "pricingCardsData.basic.pricingCardsRequestsCount.Midjourney"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.10"),
        credits: i18n.t(
          "pricingCardsData.basic.pricingCardsRequestsCount.SDXL"
        ),
      },
    },
    // { model: { value: i18n.t('pricingCardsData.models.27'), credits: i18n.t('pricingCardsData.basic.pricingCardsRequestsCount.GenerativeVideo') } },
    // { model: { value: i18n.t('pricingCardsData.models.20'), credits: i18n.t('pricingCardsData.basic.pricingCardsRequestsCount.StabilityAIVideo') } },
    // { model: { value: i18n.t('pricingCardsData.models.18'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.UnlimitedImages') } },
    // { model: { value: i18n.t('pricingCardsData.models.11'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.DallE2') } },
    // { model: { value: i18n.t('pricingCardsData.models.13'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.PhindCodeLlama34B') } },
    // { model: { value: i18n.t('pricingCardsData.models.14'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.NousHermess70B') } },
    // { model: { value: i18n.t('pricingCardsData.models.15'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.MetaLlamav270B') } },
    // { model: { value: i18n.t('pricingCardsData.models.19'), credits: "" } },
  ],
  premium: [
    {
      model: {
        value: i18n.t("pricingCardsData.models.25"),
        credits: i18n.t(
          "pricingCardsData.premium.pricingCardsRequestsCount.GPT4o"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.12"),
        credits: i18n.t(
          "pricingCardsData.premium.pricingCardsRequestsCount.DallE3"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.26"),
        credits: i18n.t(
          "pricingCardsData.premium.pricingCardsRequestsCount.Claude3.5"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.9"),
        credits: i18n.t(
          "pricingCardsData.premium.pricingCardsRequestsCount.Midjourney"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.10"),
        credits: i18n.t(
          "pricingCardsData.premium.pricingCardsRequestsCount.SDXL"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.27"),
        credits: i18n.t(
          "pricingCardsData.premium.pricingCardsRequestsCount.GenerativeVideo"
        ),
      },
    },
    // { model: { value: i18n.t('pricingCardsData.models.18'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.UnlimitedImages') } },
    // { model: { value: i18n.t('pricingCardsData.models.14'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.NousHermess70B') } },
    // { model: { value: i18n.t('pricingCardsData.models.13'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.PhindCodeLlama34B') } },
    // { model: { value: i18n.t('pricingCardsData.models.19'), credits: "" } },
  ],
  pro: [
    {
      model: {
        value: i18n.t("pricingCardsData.models.25"),
        credits: i18n.t(
          "pricingCardsData.pricingCardsTokenCount.UnilimitedReq"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.12"),
        credits: i18n.t(
          "pricingCardsData.pricingCardsTokenCount.UnlimitedImages"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.26"),
        credits: i18n.t(
          "pricingCardsData.pricingCardsTokenCount.UnilimitedReq"
        ),
      },
    },
    // { model: { value: i18n.t('pricingCardsData.models.14'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.UnilimitedReq') } },
    // { model: { value: i18n.t('pricingCardsData.models.13'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.UnilimitedReq') } },
    {
      model: {
        value: i18n.t("pricingCardsData.models.9"),
        credits: i18n.t(
          "pricingCardsData.pricingCardsTokenCount.UnlimitedImages"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.10"),
        credits: i18n.t(
          "pricingCardsData.pricingCardsTokenCount.UnlimitedImages"
        ),
      },
    },
    {
      model: {
        value: i18n.t("pricingCardsData.models.27"),
        credits: i18n.t(
          "pricingCardsData.pricingCardsTokenCount.UnilimitedReq"
        ),
      },
    },
    // { model: { value: i18n.t('pricingCardsData.models.18'), credits: i18n.t('pricingCardsData.pricingCardsTokenCount.UnlimitedImages') } },
    // { model: { value: i18n.t('pricingCardsData.models.18'), credits: "" } },
  ],
};

const getExampleCardsData = (): ExampleImgCardType[] => [
  {
    img: interiorDesignSimulation,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase1.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase1.description"
    ),
  },
  {
    img: productSimulation,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase2.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase2.description"
    ),
  },
  {
    img: tShirtSimulation,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase3.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase3.description"
    ),
  },
  {
    img: printSiumlation,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase4.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase4.description"
    ),
  },
  {
    img: appDesign,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase5.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase5.description"
    ),
  },
  {
    img: logoDesign,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase6.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase6.description"
    ),
  },
  {
    img: webSiteMockup,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase7.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase7.description"
    ),
  },
  {
    img: dishSimulation,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase8.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase8.description"
    ),
  },
  {
    img: bookCover,
    title: i18n.t("midjourneyPage.CommunityShowCase.showCase9.title"),
    description: i18n.t(
      "midjourneyPage.CommunityShowCase.showCase9.description"
    ),
  },
];

const modelsShowCase = [
  { logo: gpt4, modelName: "Chat GPT 4" },
  { logo: gpt3, modelName: "Chat GPT 3.5 Turbo" },
  { logo: dalle3, modelName: "DALL•E 3" },
  { logo: claude2, modelName: "Claude 2" },
  { logo: claude2, modelName: "Claude Instant" },
  // { logo: D2, modelName: "DALL•E 2" },
  { logo: midjourney, modelName: "Midjourney" },
  { logo: faceSwap, modelName: "Face Swap" },
  // { logo: palm, modelName: "Google PaLM 2 Bison" },
  // { logo: palm, modelName: "Google PaLM 2 Bison Code" },
  { logo: hermes, modelName: "Nous Hermess" },
  // { logo: mistral, modelName: "Mistral 7B Instruct" },
  { logo: phind, modelName: "Phind: CodeLlama 34B" },
  { logo: stableDiffusion, modelName: "Stable Diffusion XL" },
  // { logo: llama, modelName: "Meta Llama V2" },
];

const privacyPolicyData = {
  header: "PRIVACY & POLICY",
  email: "hello@unichat.one",
  links: [
    "Unichat Privacy Policy",
    "Introduction",
    "Collection of Information",
    "Use of Information",
    "Protection of Information",
    "Sharing of Information",
    "Cookies and Tracking Technologies",
    "Third-Party Links",
    "Changes to Privacy Policy",
    "Contact Us",
    "Entire Agreement",
  ],
  content: [
    {
      title: "Unichat Privacy Policy",
      description: `This privacy policy describes how we collect, use, and protect your personal information as a valued customer. By utilizing our services, you agree to adhere to the terms outlined in this privacy policy`,
    },
    {
      title: "Introduction",
      description: `Welcome to Unichat! Unichat is a service that connects WhatsApp to Midjourny & ChatGPT, a product by OpenAI. Unichat is a product by Unichat Ltd. These Terms of Service (“Terms”) govern your use of the Unichat service (“Service”) and constitute a legal agreement between you (“you” or “user”) and Unichat, Inc. (“Unichat“, “we”, “us”, or “our”), a subsidiary of Unichat Ltd. By accessing, installing, or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.`,
    },
    {
      title: "Collection of Information",
      description: `We collect necessary personal information to ensure the delivery of our services. This information includes your name, email address, phone number, and payment details. Additionally, we may gather non-personal information such as your IP address, browser type, and operating system.`,
    },
    {
      title: "Use of Information",
      description: `We utilize your personal information to provide our services, communicate with you, and enhance our overall service quality. We may also employ your information for marketing purposes, such as sending you promotional materials or offers we believe may interest you.`,
    },
    {
      title: "Protection of Information",
      description: `To safeguard your personal information from unauthorized access, disclosure, or misuse, we take reasonable precautions. We employ industry-standard security technologies and procedures. However, it is important to note that no transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security for your information.`,
    },
    {
      title: "Sharing of Information",
      description: `We do not sell, rent, or share your personal information with third parties, except when necessary to deliver our services, comply with applicable laws, or protect our rights and property.`,
    },
    {
      title: "Cookies and Tracking Technologies",
      description: `To enhance your website experience and gather insights on its usage, we utilize cookies and other tracking technologies. You can manage cookie usage through your browser settings.`,
    },
    {
      title: "Third-Party Links",
      description: `Our website may include links to third-party websites. It is important to note that we are not responsible for the privacy practices of these websites. We encourage you to review their privacy policies before sharing any personal information.`,
    },
    {
      title: "Changes to Privacy Policy",
      description: `We may update this privacy policy periodically, and any modifications will be posted on our website. By continuing to use our services after any policy changes, you indicate your acceptance of the updated terms.`,
    },
    {
      title: "Contact Us",
      description: `Should you have any questions or concerns regarding this privacy policy, please feel free to reach out to us here: `,
    },
    {
      title: "Entire Agreement",
      description: `These Terms, together with any other legal notices and agreements published by Unichat, constitute the entire agreement between you and Unichat.Please also refer to OpenAI’s Service Terms & Sharing & Publication Policy.`,
    },
  ],
};
const privacyPolicyDataHeb = {
  header: "מדיניות & פרטיות",
  email: "hello@unichat.one",
  links: [
    "מדיניות הפרטיות של יוניצ’אט",
    "מבוא",
    "איסוף מידע",
    "שימוש במידע",
    "הגנת מידע",
    "שיתוף מידע",
    "עוגיות וטכנולוגיות מעקב",
    "קישורים לצד שלישי",
    "שינויים במדיניות הפרטיות",
    "יצירת קשר",
    "הסכם מלא",
  ],
  content: [
    {
      title: "מדיניות הפרטיות של יוניצ’אט",
      description: `מדיניות הפרטיות הזו מתארת איך אנחנו אוספים, משתמשים ומגנים על המידע האישי שלך כלקוח יקר. בקבלת השירותים שלנו, אתה מסכים לתנאים המתוארים במדיניות הפרטיות הזו.`,
    },
    {
      title: "מבוא",
      description: `ברוכים הבאים ל-Unichat, שירות המחבר אותך ל- Midjourney, ChatGPT, CLAUDE ועוד כלי בינה מלאכותית ישירות מהוואטסאפ והדפדפן שלך. יוניצ’אט הוא מוצר של Unichat Ltd. תנאי השירות (“התנאים”) מנהלים את השימוש שלך בשירות Unichat (“השירות”) ומהווים הסכם משפטי בינך (“אתה” או “המשתמש”) ובינינו, Unichat Inc. (“Unichat”, “אנחנו”, “אנחנו”, או “שלנו”), חברה בת של Unichat Ltd. בגישה, התקנה או שימוש בשירות, אתה מכיר כי קראת, הבנת והסכמת להיכנס לתוך התנאים הללו.`,
    },
    {
      title: "איסוף מידע",
      description: `אנחנו אוספים מידע אישי נחוץ כדי להבטיח את מסירת השירותים שלנו. המידע הזה כולל את שמך, כתובת הדוא”ל שלך, מספר הטלפון שלך ופרטי התשלום שלך. בנוסף, אנחנו עלולים לאסוף מידע לא אישי כמו כתובת ה-IP שלך, סוג הדפדפן ומערכת ההפעלה.`,
    },
    {
      title: "שימוש במידע",
      description: `אנחנו משתמשים במידע האישי שלך כדי לספק את השירותים שלנו, לתקשר איתך ולשפר את איכות השירות הכוללת שלנו. אנחנו יכולים גם להשתמש במידע שלך לצורך שיווק, כמו שליחת חומרי פרסום או הצעות שאנחנו מאמינים שיעניינו אותך.`,
    },
    {
      title: "הגנת המידע",
      description: `כדי להגן על המידע האישי שלך מפני גישה, חשיפה או שימוש לא מורשה, אנחנו מקפידים על אמצעי הגנה סבירים. אנחנו משתמשים בטכנולוגיות ובהליכים תקניים בתעשייה לאבטחת המידע. אף על פי כן, חשוב לציין כי אין העברת מידע דרך האינטרנט או אחסון אלקטרוני שהם לחלוטין מאובטחים, ואנחנו לא יכולים להבטיח אבטחה מוחלטת למידע שלך.`,
    },
    {
      title: "שיתוף מידע",
      description: `אנחנו לא מוכרים, משכירים או משתפים את המידע האישי שלך עם צד שלישי, למעט כאשר זה נדרש כדי לספק את השירותים שלנו, לעמוד בחוקי ורגולציות או להגן על זכויותינו ועל רכושנו.`,
    },
    {
      title: "עוגיות וטכנולוגיות מעקב",
      description: `כדי לשפר את חוויית המשתמש שלך ולאסוף נתונים על שימושך במערכת, אנחנו משתמשים בעוגיות ובטכנולוגיות מעקב אחרות. ניתן לנהל את שימוש העוגיות דרך הגדרות הדפדפן שלך.`,
    },
    {
      title: "קישורים לצד שלישי",
      description: `האתר שלנו עשוי לכלול קישורים לאתר של צד שלישי. חשוב לציין כי איננו אחראים למדיניות הפרטיות של אתרים אלה. אנחנו ממליצים לך לסקור את מדיניות הפרטיות שלהם לפני השיתוף של כל מידע אישי.`,
    },
    {
      title: "שינויים במדיניות הפרטיות",
      description: `אנחנו עשויים לעדכן את מדיניות הפרטיות הזו מעת לעת, וכל שינוי יתפרסם באתר שלנו. על ידי המשך השימוש בשירותים שלנו לאחר כל שינוי במדיניות, ציינת את הקבלה שלך לתנאים המעודכנים.`,
    },
    {
      title: "יצירת קשר",
      description: `אם יש לך שאלות או דאגות בנוגע למדיניות הפרטיות הזו, ניתן ליצור קשר כאן: `,
    },
    {
      title: "הסכם מלא",
      description: `תנאי השירות הללו, ביחד עם כל הודעות משפטיות נוספות והסכמים שפורסמו על ידי Unichat, מהווים את ההסכם המלא בינך לבין Unichat. נא לעיין גם בתנאי השימוש ומדיניות השיתוף והפרסום של OpenAI.`,
    },
  ],
};

const tosData = {
  header: "TERMS OF USE",
  email: "hello@unichat.one",
  links: [
    "Terms of Use",
    "Use of the Software",
    "User Responsibilities and Obligations",
    "Fair Use Policy",
    "Limitation of Liability",
    "Termination",
    "Modification of Terms",
    "Governing Law and Jurisdiction",
    "Contact Us",
  ],
  content: [
    {
      title: "Terms of Use",
      description: `Welcome to Unichat! These terms of use (“Terms”) govern your access to and use of Unichat software, including any updates, new features, or enhancements (collectively referred to as the “Software”), provided by Unichat Ltd. (“Unichat,” “we,” “our,” or “us”).
By using the Software, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from accessing or using the Software.`,
    },
    {
      title: "Use of the Software",
      description: `1.1 Integration of AI Tools: Unichat provides a platform that integrates advanced and premium AI tools with users who wish to utilize them. The Software currently operates through the WhatsApp application, allowing users to access these tools in one place.

1.2 Ownership of Generated Content: Unichat does not hold any rights to the content generated by paying users of the Software. However, Unichat retains ownership rights to the content generated by non-paying users (“Freemium Users”).
  
1.3 Ownership of Intellectual Property: Unichat maintains all rights to Unichat’s logos, trademarks, domain, codes, software developments, and any other intellectual property associated with the Software.`,
    },
    {
      title: "User Responsibilities and Obligations",
      description: `2.1 Accurate Information: You must provide accurate and complete information while using the Software. Unichat reserves the right to verify the information provided and suspend or terminate your access if any inaccuracies or falsehoods are discovered.

  2.2 Prohibited Activities: You agree not to engage in any activities that may:
  a) Violate any applicable laws, regulations, or third-party rights.
  b) Interfere with or disrupt the Software’s functionality.
  c) Impersonate any person or entity or misrepresent your affiliation with any person or entity.
  d) Use the Software for any illegal, unethical, or unauthorized purposes.
  e) Attempt to gain unauthorized access to any part of the Software.`,
    },
    {
      title: "Fair Use Policy",
      description: `3.1 With the “Unlimited” version, users receive 5,000 Unichat credits to utilize as desired within reasonable terms of service. For instance, credits may be exchanged to generate 1,000 premium images or 1 million LLM tokens. We reserve the right to ban accounts displaying fraudulent or abusive behavior with no refunds issued. Our goal is providing a quality service for all.`,
    },
    {
      title: "Limitation of Liability",
      description: `4.1 Disclaimer of Damages and Losses: Unichat shall not be held liable for any damages or losses incurred by users while using the WhatsApp application. You acknowledge and agree that Unichat is not responsible for the accuracy, reliability, or availability of the website or the content provided by WhatsApp.`,
    },
    {
      title: "Termination",
      description: `5.1 Termination by Unichat: Unichat reserves the right to suspend or terminate your access to the Software, in whole or in part, at any time and for any reason without prior notice.
4.2 Termination by Users: Users may terminate their use of the Software at any time by ceasing to access or use the Software.`,
    },
    {
      title: "Modification of Terms",
      description: `6.1 Changes to the Terms: Unichat may modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting the revised Terms on the Unichat website or within the Software. Your continued use of the Software after the posting of any modifications constitutes your acceptance of the revised Terms.`,
    },
    {
      title: "Governing Law and Jurisdiction",
      description: `7.1 Applicable Law: These Terms shall be governed by and construed in accordance with the laws of Israel, without regard to its conflict of laws principles.
6.2 Dispute Resolution: Any disputes arising out of or relating to these Terms or the use of the Software shall be resolved exclusively through arbitration in accordance with the rules of IICA in Israel.`,
    },
    {
      title: "Contact Us",
      description: `Should you have any questions or concerns regarding this terms of use, please feel free to reach out to us here: 
  `,
    },
    {
      title: "Privacy policy",
      description: `Click here to view the Unichat privacy policy: https://lp.unichat.one/privacy-policy/`,
    },
  ],
};
const tosDataHeb = {
  header: "תנאי שימוש",
  email: "hello@unichat.one",
  links: [
    "תנאי שימוש",
    "שימוש בתוכנה",
    "אחריות ומחויבות משתמש",
    "מדיניות שימוש הוגן",
    "הגבלת אחריות",
    "סיום",
    "שינוי בתנאים",
    "חוק החוקה וסמכות משפטית",
    "יצירת קשר",
  ],
  content: [
    {
      title: "תנאי שימוש",
      description: `ברוכים הבאים ל-Unichat! תנאי השימוש הללו ("התנאים") מנהלים את גישתך ושימושך בתוכנה של Unichat, כולל כל עדכונים, תכונות חדשות או שיפורים (ביחד נקראים "התוכנה"), המסופקת על ידי Unichat Ltd. ("Unichat", "אנחנו", "שלנו" או "לנו").

על ידי שימוש בתוכנה, אתה מסכים להיכנס לתוקף של התנאים הללו. אם אינך מסכים לתנאים הללו, אנא הימנע מגישה או שימוש בתוכנה.`,
    },
    {
      title: "שימוש בתוכנה",
      description: `1.1 שילוב של כלי AI: UniChat מספקת פלטפורמה המשלבת כלים מתקדמים ופרימיום של AI עם משתמשים המעוניינים להשתמש בהם. התוכנה פועלת כיום דרך אפליקציית WhatsApp, המאפשרת למשתמשים לגשת לכלים אלו במקום אחד.

1.2 בעלות על תוכן שנוצר: UniChat אינה מחזיקה בזכויות לתוכן שנוצר על ידי משתמשים משלמים בתוכנה. אולם, UniChat שומרת על זכויות הבעלות לתוכן שנוצר על ידי משתמשים שאינם משלמים ("משתמשים חינמיים").
    
1.3 בעלות על קניין רוחני: UniChat שומרת על כל הזכויות ללוגואים, לסימני המסחר, לדומיין, לקודים, לפיתוחי התוכנה ולכל קניין רוחני אחר הקשור לתוכנה.`,
    },
    {
      title: "אחריות ומחויבות משתמש",
      description: `2.1 מידע מדויק: עליך לספק מידע מדויק ומלא במהלך השימוש בתוכנה. UniChat שומרת לעצמה את הזכות לאמת את המידע המסופק ולהשעות או לסיים את הגישה שלך אם נמצאות אי מדויקויות או שקרים.

    2.2 פעילויות אסורות: אתה מסכים שלא לעסוק בפעילויות המפרות:
    א) כל דין ותקנה יחודיים, זכויות צד שלישי או כלליות.
    ב) להפריע לפונקציונליות התוכנה או להפריע בה.
    ג) להתחזות לכל אדם או ייצוג של קישור שלך לכל אדם או יישות.
    ד) להשתמש בתוכנה לכל מטרות לא חוקיות, לא אתיות או לא מורשות.
    ה) לנסות לגשת לחלקים שלא נכנסים ברשותך באופן לא מורשה.`,
    },
    {
      title: "מדיניות שימוש הוגן",
      description: `3.1 עם חבילה “ללא הגבלה”, המשתמשים מקבלים 5,000 נקודות Unichat לניצול כרצונם במסגרת תנאי השימוש הסבירים. לדוגמה, ניתן להמיר נקודות אלה ליצירת 1,000 תמונות פרמיום או 1 מיליון אסימוני LLM. אנו שומרים לעצמנו את הזכות לחסום חשבונות המפגינים התנהגות מרמה או פוגענית ללא החזר כספי. מטרתנו היא לספק שירות איכותי לכולם.`,
    },
    {
      title: "הגבלת אחריות",
      description: `4.1 כתב ויתור על נזקים והפסדים: UniChat לא יהיה אחראי לכל נזקים או הפסדים שנגרמים למשתמשים במהלך השימוש באפליקציית WhatsApp. אתה מכיר ומסכים כי UniChat אינה אחראית לדיוק, לאמינות או לזמינות האתר או לתוכן המסופק על ידי WhatsApp.`,
    },
    {
      title: "סיום",
      description: `5.1 סיום על ידי UniChat: UniChat שומרת לעצמה את הזכות להשעות או לסיים את הגישה שלך לתוכנה, בשלמותה או בחלקה, בכל עת ולכל סיבה בלי הודעה מוקדמת.
    4.2 סיום על ידי המשתמשים: המשתמשים יכולים לסיים את השימוש שלהם בתוכנה בכל עת על ידי הפסקת הגישה או השימוש בתוכנה.
    `,
    },
    {
      title: "שינוי בתנאים",
      description: `6.1 שינויים בתנאים: UniChat רשאית לשנות את התנאים הללו בכל עת בלי הודעה מוקדמת. כל שינוי יהיה תקף מיד לאחר פרסום התנאים המתוקנים באתר של UniChat או בתוך התוכנה. השימוש המתמשך שלך בתוכנה לאחר פרסום השינויים מהווה את הסכמתך לתנאים המתוקנים.`,
    },
    {
      title: "חוק החוקה וסמכות משפטית",
      description: `7.1 דין יחול: התנאים הללו יחולו ויפורשו על פי דיני מדינת ישראל, ללא קשר לעקרונות הסכסוך שלה.
6.2 פתרון סכסוכים: כל סכסוכים הנובעים מהתנאים הללו או משימוש בתוכנה יפותרו באופן בלעדי על ידי פתרון סכסוכים בהתאם לחוקי IICA בישראל.`,
    },
    {
      title: "יצירת קשר",
      description: `אם יש לך שאלות או דאגות בנוגע לתנאי השימוש שלנו, ניתן ליצור קשר כאן: `,
    },
    {
      title: "מדיניות פרטיות",
      description: `לצפיה במדיניות הפרטיות יש לעבור לדף הבא: https://lp.unichat.one/privacy-policy?lang=he-IL`,
    },
  ],
};

export {
  getAccordionsData,
  getFreeAccordionsData,
  infoCardsData,
  getPricingCardsData,
  getFigureCardsData,
  getExampleCardsData,
  getServiceDataCardsData,
  modelsShowCase,
  privacyPolicyData,
  privacyPolicyDataHeb,
  tosData,
  tosDataHeb,
};
