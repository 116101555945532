import React, { useState } from "react";
import styles from "./LoginPage.module.scss";
import { TextField, Button } from "@mui/material";
import unichatLogo from "../../assets/LoginPage/unichatLogo.png";
import { object, string, number, date, InferType } from "yup";
import { extractUserData, getAllUTMData, storage } from "../../utils/utils";
import OTPModal from "../../cmps/OTPModal/OTPModal.cmp";
import CircularProgress from "@mui/material/CircularProgress";
import { userVerification } from "../../utils/service";
import { useTranslation } from "react-i18next";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { useRecoilValue } from "recoil";
import { languageModel } from "../../store/atoms/generalAtoms";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import whiteWhatsApp from "../../assets/whiteWhatsApp.png";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import "animate.css";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

let emailType = object({ email: string().email() });

const LoginPage = (props: any) => {
  const { setStep, setRegistered } = props;
  const [userEmail, setUserEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [userToken, setUserToken] = useState<string>("");
  const [openOTPModal, setOpenOTPModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [userEmailAndToken, setUserEmailAndToken] = useState<any>({
    userEmail: "",
    token: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [switchConnectionType, setSwitchConnectionType] =
    useState<boolean>(false);
  const recoilLangModel = useRecoilValue(languageModel);
  const [t, i18n] = useTranslation();

  const sendPhoneNumberValidate = async () => {
    setIsLoading(true);
    if (switchConnectionType) {
      if (!sendDataValidate()) {
        setIsLoading(false);
        return;
      }
    }
    try {
      const utmData = getAllUTMData(phoneNumber, "whatsApp");
      const userPhone = phoneNumber.slice(1);
      const response = await userVerification.phoneNumberSignIn(
        userPhone,
        utmData
      );

      storage.saveToStorage("userId", response.userId);
      handleReponseAndOpenOTP(response);
      storage.saveToStorage("userPhone", userPhone);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage("Error login");
    }
  };

  const sendDataValidate = (): boolean => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      if (errorMessage !== "") {
        setErrorMessage("");
      }
      return true;
    }
    setErrorMessage(t("LoginPage.HelperErrorMessage.WhatsApp"));
    return false;
  };

  const handleReponseAndOpenOTP = (response: any) => {
    setUserToken(response.token);
    storage.saveToStorage("userId", response.userId);
    if (response.newUser) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "start_sign_up",
        user_id: response.userId,
        user_email: userEmail?.toLocaleLowerCase()!,
        user_phone: phoneNumber,
      });
    }
    setOpenOTPModal(true);
    setIsLoading(false);
    setErrorMessage("");
    response.newUser && setIsNewUser(true);
  };

  const sendEmailValidate = async () => {
    try {
      setIsLoading(true);
      const utmData = getAllUTMData(userEmail?.toLocaleLowerCase()!, "email");
      const email = await emailType.validate({ email: userEmail });
      if (email !== undefined) {
        const response = await userVerification.emailSignIn(
          userEmail?.toLocaleLowerCase()!,
          utmData
        );
        handleReponseAndOpenOTP(response);
        storage.saveToStorage("userEmail", userEmail?.toLocaleLowerCase()!);
      }
      // console.log("🚀 ~ file: login.tsx:13 ~ sendEmailValidate ~ email:", email)
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(t("LoginPage.HelperErrorMessage.Email"));
    }
  };

  const handleKeypress = (e: any) => {
    // It's triggers by pressing the enter key
    if (e.keyCode === 13) {
      !switchConnectionType ? sendEmailValidate() : sendPhoneNumberValidate();
      e.preventDefault();
    }
  };

  const switchConnectionTypes = () => {
    if (userEmail) {
      setUserEmail("");
    }
    if (phoneNumber) {
      setPhoneNumber("");
    }
    setErrorMessage("");
    setSwitchConnectionType(!switchConnectionType);
  };

  return (
    <div
      className={styles.loginPageContainer}
      onKeyDown={handleKeypress}
      style={{ direction: recoilLangModel.includes("he") ? "rtl" : "ltr" }}
    >
      <header className={styles.loginPageContainerHeader}>
        {/* <img src={unichatLogo} alt="unichat logo - a unicorn with a suit" /> */}
      </header>
      <main className={styles.loginPageMainSection}>
        <section className={styles.widgetAutoLayout}>
          <div className={styles.loginContainer}>
            {/* <h1>Welcome to Unichat</h1> */}
            {/* <h3>{t('LoginPage.SubHeaderh3')}</h3> */}
            <div className={styles.inputAndHeaderContainer}>
              <h3>{t("LoginPage.SubHeaderh3")}</h3>
              {!switchConnectionType ? (
                <>
                  {recoilLangModel.includes("he") ? (
                    <CacheProvider value={cacheRtl}>
                      <TextField
                        FormHelperTextProps={{ className: styles.helperText }}
                        label={t("LoginPage.TextFieldLabel")}
                        fullWidth
                        onChange={(e) => setUserEmail(e.target.value)}
                        helperText={errorMessage ? errorMessage : null}
                        color={errorMessage.length > 1 ? "error" : "primary"}
                        sx={{
                          borderColor: errorMessage.length > 0 ? "red" : "",
                          position: "relative",
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#4F46E5",
                            },
                          },
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "#4F46E5",
                          },
                        }}
                      />
                    </CacheProvider>
                  ) : (
                    <TextField
                      FormHelperTextProps={{ className: styles.helperText }}
                      label={t("LoginPage.TextFieldLabel")}
                      fullWidth
                      onChange={(e) => setUserEmail(e.target.value)}
                      helperText={errorMessage ? errorMessage : null}
                      color={errorMessage.length > 1 ? "error" : "primary"}
                      sx={{
                        borderColor: errorMessage.length > 0 ? "red" : "",
                        position: "relative",
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#4F46E5",
                          },
                        },
                        "& .MuiInputLabel-outlined.Mui-focused": {
                          color: "#4F46E5",
                        },
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <div className={styles.PhoneNumberForm}>
                    {/* <span className={styles.PhoneNumberFormTitle}>{P}</span> */}
                    <PhoneInput
                      defaultCountry={"IL"}
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      withCountryCallingCode
                      international
                      onChange={setPhoneNumber}
                      rules={{ required: true }}
                      className={styles.PhoneInput}
                    />
                    {errorMessage && (
                      <span
                        className={`${styles.helperText} ${styles.errorMessage}`}
                        style={{
                          visibility:
                            errorMessage === " " ? "hidden" : "visible",
                          fontSize: "0.75rem",
                          alignSelf: "flex-start",
                          margin: "3px 0 0 14px",
                        }}
                      >
                        {errorMessage === " " ? "error" : errorMessage}
                      </span>
                    )}
                  </div>
                </>
              )}
              <p className={styles.emailPrivacyText}>
                {t("LoginPage.PrivacyText")}
              </p>
            </div>
            <Button
              className={styles.continueButton}
              onClick={() => {
                !switchConnectionType
                  ? sendEmailValidate()
                  : sendPhoneNumberValidate();
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("LoginPage.ContinueButton")
              )}
            </Button>
          </div>
        </section>
        <section className={styles.connectWithSection}>
          <button
            key={`${switchConnectionType}`}
            className={`${styles.connectWithOption} animate__animated ${
              switchConnectionType ? "animate__flipInY" : "animate__flipInY"
            }`}
            onClick={switchConnectionTypes}
          >
            <span>
              {!switchConnectionType
                ? t("LoginPage.ConnectionOptionButtonText.WhatsApp")
                : t("LoginPage.ConnectionOptionButtonText.Email")}
            </span>
            {!switchConnectionType ? (
              <img
                src={whiteWhatsApp}
                alt="whatsapp logo"
                width={30}
                height={30}
              />
            ) : (
              <MailOutlinedIcon sx={{ width: "30px", height: "30px" }} />
            )}
          </button>
        </section>
      </main>
      <footer className={styles.loginPageFooter}>
        <a
          href="https://unichat.one/terms-of-use/"
          target="_blank"
          rel="noreferrer"
        >
          {t("LoginPage.TermsOfUse")}
        </a>
        <span> | </span>
        <a
          href="https://unichat.one/privacy-policy-2/"
          target="_blank"
          rel="noreferrer"
        >
          {t("LoginPage.PrivacyPolicy")}
        </a>
      </footer>
      {openOTPModal && (
        <OTPModal
          closeModal={setOpenOTPModal}
          setStep={setStep}
          setRegistered={setRegistered}
          userToken={userToken}
        />
      )}
    </div>
  );
};

export default LoginPage;
